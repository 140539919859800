export const SUPPORTED_LOCALES = [
  {
    code: 'nl',
    base: '',
    flag: 'nl',
    name: 'NL',
    translations: '/translations/nl.json',
    iso: 'nl'
  }
]
